import { graphql } from "gatsby"
import React from "react"
import { Box, Heading, Text } from "theme-ui"

import BlockContainer from "../components/block-container"
import ContentfulChaoticPicturesBlock from "../components/contentful-chaotic-pictures-block"
import ContentfulFooterBlock from "../components/contentful-footer-block"
import ContentfulRichTextBlock from "../components/contentful-rich-text-block"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostPage = ({ pageContext, data }) => {
  const blogPostPage = pageContext.node
  const { contentfulHomePage } = data
  const { headerLabel } = contentfulHomePage

  return (
    <>
      <SEO
        description={blogPostPage.seoDescription?.seoDescription}
        title={blogPostPage.seoTitle || blogPostPage?.title}
      />
      <Layout headerLabel={headerLabel}>
        <BlockContainer noPaddings sxOverride={{ pb: 5, pt: 7 }}>
          <Heading as="h1" sx={{ textAlign: "center" }} variant="h1">
            {blogPostPage.title}
          </Heading>
          <Heading as="h3" sx={{ mt: 3, textAlign: "center" }} variant="h3">
            {blogPostPage.categories?.join(", ")}
          </Heading>
          {/* <Box sx={{ mt: 3 }}>
            {blogPostPage.tags?.map((tag, index) => (
              <Text
                key={`tag-${index}`}
                sx={{ textAlign: "center" }}
                variant="caption1"
              >
                + {tag}
              </Text>
            ))}
          </Box> */}
        </BlockContainer>
        <ContentfulRichTextBlock richText={blogPostPage.richText1} />
        <ContentfulChaoticPicturesBlock
          floatingPictures={blogPostPage.floatingPictures}
        />
        <ContentfulRichTextBlock richText={blogPostPage.richText2} />
        <ContentfulFooterBlock
          address={blogPostPage.footer?.address?.address}
          backgroundColor={blogPostPage.footer?.backgroundColor}
          copyright={blogPostPage.footer?.copyright?.copyright}
          socialLinksTitle={blogPostPage.footer?.socialLinksTitle}
          blogTitle={blogPostPage.footer?.blogTitle}
          newsletterJoinTitle={blogPostPage.footer?.newsletterJoinTitle}
          facebookLink={blogPostPage.footer?.facebookLink}
          form={blogPostPage.footer?.form}
          gif={blogPostPage.footer?.gIf}
          gifDescription={blogPostPage.footer?.gifDescription}
          instagramLink={blogPostPage.footer?.instagramLink}
          linkedInLink={blogPostPage.footer?.linkedInLink}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    contentfulHomePage {
      headerLabel
    }
  }
`

export default BlogPostPage
