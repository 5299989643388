/** @jsx jsx */

import React from "react"
import { jsx } from "theme-ui"
import { AspectRatio, Box, Flex, Heading } from "theme-ui"

import { ContentfulFloatingPicture } from "../../graphql-types"
import BlockContainer from "./block-container"

const HorizontalBox = ({ children }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <AspectRatio ratio={2 / 1}>{children}</AspectRatio>
    </Box>
  )
}

const SquareBox = ({ children }) => {
  return (
    <Box sx={{ width: ["100%", "100%", "50%","50%"] }}>
      <AspectRatio ratio={1 / 1}>{children}</AspectRatio>
    </Box>
  )
}

const VerticalBox = ({ children }) => {
  return (
    <Box sx={{ width: ["100%", "100%", "50%","50%"] }}>
      <AspectRatio ratio={1 / 1.5}>{children}</AspectRatio>
    </Box>
  )
}

const ContentfulChaoticPicturesBlock = ({
  floatingPictures,
  text,
}) => {
  return (
    <BlockContainer>
      <Flex sx={{ flexWrap: "wrap" }}>
        {text && (
          <SquareBox>
            <Flex
              sx={{
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Heading as="h3" sx={{ whiteSpace: "pre-wrap" }} variant="h3">
                {text}
              </Heading>
            </Flex>
          </SquareBox>
        )}
        {(floatingPictures)?.map(
          (floatingPicture, index) => {
            const content = (
              <Box sx={{ height: "100%", width: "100%" }}>
                {floatingPicture.vimeoEmbed && (
                  <iframe
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    frameBorder="0"
                    height="100%"
                    src={floatingPicture.vimeoEmbed?.vimeoEmbed}
                    title={floatingPicture.vimeoEmbed?.vimeoEmbed}
                    width="100%"
                  />
                )}
                {floatingPicture.picture && (
                  <img
                    alt=""
                    src={floatingPicture.picture?.resize?.src || ""}
                    sx={{
                      height: "100%",
                      objectFit: "contain",
                      px: ["12px", "20px", "48px", "48px"],
                      transform: `scale(${
                        parseFloat(floatingPicture.downscaleFactor) /
                        100
                      })`,
                      width: "100%",
                    }}
                  />
                )}
              </Box>
            )
            if (floatingPicture.boxType === "Horizontal") {
              return <HorizontalBox key={`horizontal-${index}`}>{content}</HorizontalBox>
            } else if (floatingPicture.boxType === "Square") {
              return <SquareBox key={`square-${index}`}>{content}</SquareBox>
            } else if (floatingPicture.boxType === "Vertical") {
              return <VerticalBox key={`vertical-${index}`}>{content}</VerticalBox>
            }
          },
        )}
      </Flex>
    </BlockContainer>
  )
}

export default ContentfulChaoticPicturesBlock
